<template>
  <div>
    <b-alert
      variant="info"
      :show="true"
    >
      <div class="alert-body">
        <p>Please login to the <a
          href="https://dolphindevelopmentgroup.surveysparrow.com/"
          target="_blank"
        >Dolphin Development Group SurveySparrow portal</a> to adjust questions.</p>
      </div>
    </b-alert>

    <b-list-group v-if="false">
      <b-list-group-item
        v-for="item in loggableItems"
        :key="item.id"
        :to="{ name: 'questionnaires-edit', params: { loggableItemId: item.id }}"
        :disabled="!item.visible"
      >
        {{ item.name }}
      </b-list-group-item>
    </b-list-group>
  </div>

</template>

<script>
import { BListGroup, BListGroupItem, BAlert } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BAlert,
  },
  data() {
    return {
      options: {},
      hideInformation: true,
      hideSocial: true,
      hideNotifications: true,
    }
  },
  computed: {
    ...mapGetters('loggableItems', ['loggableItems']),
  },
  async created() {
    await this.getLoggableItems()
  },
  methods: {
    ...mapActions('loggableItems', ['getLoggableItems']),
  },
  metaInfo() {
    return { title: this.$t('page_titles.edit_log'), titleTemplate: 'DenTyme - %s' }
  },
}
</script>
